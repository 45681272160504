
//import { URL } from "@/Conf";

export default {
  async auth(context, payload) {
    localStorage.setItem('locid', payload);//localStorage to save datas on local browser storage
    context.commit('setLoc', {
      LocationId: payload,  
    });
  },
  // tryLogin(context) {
  //   const token = localStorage.getItem('token');
  //   const userId = localStorage.getItem('userId');
  //   const tokenExpiration = localStorage.getItem('tokenExpiration');

  //   const expiresIn = +tokenExpiration - new Date().getTime();//+ to parse data to integer

  //   if (expiresIn < 0) {
  //     return;
  //   }

  //   timer = setTimeout(function() {
  //     context.dispatch('autoLogout');
  //   }, expiresIn);

  //   if (token && userId) {
  //     context.commit('setUser', {
  //       token: token,
  //       userId: userId
  //     });
  //   }
  // },
  // logout(context) {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('userId');
  //   localStorage.removeItem('tokenExpiration');

  //   clearTimeout(timer);

  //   context.commit('setUser', {
  //     token: null,
  //     userId: null
  //   });
  // },
  // autoLogout(context) {
  //   context.dispatch('logout');
  //   context.commit('setAutoLogout');
  // }
};
