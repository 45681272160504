<template>
    <div class="disnon">
                        <div class="desc" style="margin-bottom: 10px;">
                            <p v-if="description">{{ description }}</p>
                        </div>

<div v-if="date"> <b> Release Date: </b> {{ date }} </div>
<div  v-if="dir"> <b> Director: </b> {{ dir }}</div>
<div  v-if="producer"> <b> Production: </b> {{ producer }}</div>
<div  v-if="cast"> <b> Staring: </b> {{ cast }}</div>
<div class="bid" style="margin-top: 15px;">
<span>
<a class="venobox"
                              data-autoplay="true"
                              data-vbtype="video"
                              v-if="trailer"
                              :href="trailer">
                              <font-awesome-icon icon="fa-solid fa-play" class="mdvid" />
                             
                             </a> </span>
                              <span class="bg3d bor5 marl7" v-if="is3d">3D</span>
                              <span :class="certclass[cert]" v-if="cert">{{cert}}</span>
</div>
</div>
</template>
<script>
import venobox from "venobox";
export default {
    props:['description','date','dir','cast','producer','trailer','is3d','cert'],
    data:()=>({certclass:{"U":"marl7 ce1","U/A":"marl7 ce2","A":"marl7 ce3"},}),
    mounted(){
        new venobox();
    }
}
</script>
<style>
@import "../../../node_modules/venobox";
</style>
